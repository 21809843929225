import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { sendPasswordResetEmail } from '@firebase/auth';

import {
  Box, FormField, TextInput, Button, Image,
} from 'grommet';

import { SignInLink } from './SignIn';

import { auth } from '../firebase';
import Routes from '../routes';
import logo from '../img/logo.png';
import { MustNotBeLoggedIn } from './withAuthorization';

export const PasswordForgetForm = () => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<Error | null>(null);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setEmail('');
        setError(null);
      })
      .catch((e: Error) => {
        setError(e);
      });
    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <FormField htmlFor="email" label="Email" content={{ pad: 'large' }}>
        <TextInput id="email" value={email} onChange={(event) => setEmail(event.target.value)} type="email" />
      </FormField>
      {error && <p>{error.message}</p>}
      <Box>
        <Button label="Reset Password" type="submit" primary fill />
      </Box>
    </form>
  );
};

export const PasswordForgetFormWithRouter = withRouter(PasswordForgetForm);

export const PasswordForgetLink = () => (
  <p>
    <Link to={Routes.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

const PasswordForgetPage = () => (
  <Box align="center" fill pad="medium" background="brand">
    <Link to={Routes.LANDING}>
      <Image src={logo} alt="vegstreak logo" style={{ maxWidth: '350px' }} />
    </Link>
    <Box width="medium" pad="medium" round="xsmall" background="light-1">
      <PasswordForgetFormWithRouter />
      <SignInLink />
    </Box>
  </Box>
);

export default MustNotBeLoggedIn(PasswordForgetPage);
