import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Grommet } from 'grommet';

import AccountPage from './components/Account';
import LandingPage from './components/Landing';
import NoMatchPage from './components/NoMatch';
import PasswordForgetPage from './components/PasswordForget';
import SignInPage from './components/SignIn';
import SignUpPage from './components/SignUp';
import HomePage from './components/Home';
import InfiniteEntriesPage from './components/InfiniteEntries';

import Routes from './routes';
import theme from './theme';
import './overrides.css';

const Index = () => (
  <BrowserRouter>
    <Grommet full theme={theme}>
      <Switch>
        <Route exact path={Routes.LANDING} component={LandingPage} />
        <Route exact path={Routes.SIGN_IN} component={SignInPage} />
        <Route exact path={Routes.SIGN_UP} component={SignUpPage} />
        <Route exact path={Routes.HOME} component={HomePage} />
        <Route exact path={Routes.ACCOUNT} component={AccountPage} />
        <Route exact path={Routes.PASSWORD_FORGET} component={PasswordForgetPage} />
        <Route exact path={Routes.ENTRIES} component={InfiniteEntriesPage} />
        <Route component={NoMatchPage} />
      </Switch>
    </Grommet>
  </BrowserRouter>
);

ReactDOM.render(<Index />, document.getElementById('root'));

document.body.classList.remove('loading');
document.body.style.margin = '0';
