import React from 'react';
import { signOut } from '@firebase/auth';
import { logEvent } from '@firebase/analytics';
import { useHistory } from 'react-router-dom';
import { History } from 'history';

import { Button } from 'grommet';

import { analytics, auth } from '../firebase';
import Routes from '../routes';

export const doSignOut = (history: History) => {
  logEvent(analytics, 'logout', { method: 'email' });
  signOut(auth);
  history.push(Routes.SIGN_IN);
};

export const SignOutButton = () => {
  const history = useHistory();
  return (
    <Button type="button" onClick={() => doSignOut(history)}>
      Sign Out
    </Button>
  );
};
