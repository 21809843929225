import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyDNJf4Py6gh3lnNcu9yPfEob-B99l-Dg2w',
  authDomain: 'vegstreak.firebaseapp.com',
  databaseURL: 'https://vegstreak.firebaseio.com',
  projectId: 'vegstreak',
  storageBucket: 'vegstreak.appspot.com',
  messagingSenderId: '372364499506',
  appId: '1:372364499506:web:432e44c4cf6bcc80987a2b',
};

const firebaseApp = initializeApp(config);

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const analytics = getAnalytics();
