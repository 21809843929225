/* eslint-disable max-len */
// import { css } from 'styled-components';

import { deepFreeze } from 'grommet/utils';

export default deepFreeze({
  global: {
    breakpoints: {
      small: {
        value: 1056,
      },
    },
    colors: {
      background: '#187878',
      brand: '#187878',
      'accent-1': '#EC821D', // light-orange
      'accent-2': '#70AC4F', // dark-green
      'accent-3': '#E5721A', // dark-orange
      'accent-4': '#E7E9CD', // light-green
      black: '#1A1A1A', // black
      control: {
        dark: 'accent-1',
        light: 'accent-1',
      },
      focus: undefined, // dark-orange
    },
    font: {
      family:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",  "Helvetica Neue", Arial, sans-serif,  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
  },
  anchor: {
    color: {
      dark: '#FD6FFF',
      light: '#9060EB',
    },
  },
  button: {
    padding: {
      horizontal: '6px',
    },
  },
});
