import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Heading, Paragraph } from 'grommet';

export default () => {
  const location = useLocation();
  return (
    <Box align="center" justify="center" pad="large" fill background="brand">
      <Heading as="h1">
        vegstreak
      </Heading>
      <Paragraph size="large">
        No match for
        {' '}
        <code>{location.pathname}</code>
      </Paragraph>
    </Box>
  );
};
