import React, { useEffect, useState } from 'react';
import { DocumentData, QuerySnapshot } from '@firebase/firestore';

import {
  Box, Text, Stack, InfiniteScroll, Heading,
} from 'grommet';
import { Radial } from 'grommet-icons';

import { Entry, Meal } from '../types';
import { dietColor, dietLabel, sortEntry } from '../utils';
import SidebarLayout from './SidebarLayout';
import { auth, watchEntries } from '../firebase';
import { MustBeLoggedIn } from './withAuthorization';

// Helper to find the background color of a row based on it's Entry
const backgroundForEntry = (entry: Entry): string => {
  const min = Math.min(...Object.values(entry).filter(Number));
  return dietColor(min);
};

const InfiniteEntries = ({ entries }: { entries: Entry[] }) => (
  <Box background="light-1" round="xsmall">
    <Box round="xsmall" pad="medium" direction="row" justify="between" margin={{ vertical: 'small' }}>
      <Radial size="large" color="light-1" />
      <Box basis="10%"><Text weight="bold">Date</Text></Box>
      <Box basis="10%"><Text weight="bold">Breakfast</Text></Box>
      <Box basis="10%"><Text weight="bold">Lunch</Text></Box>
      <Box basis="10%"><Text weight="bold">Dinner</Text></Box>
      {/* <Box basis="10%"><Text weight="bold">Snacks</Text></Box> */}
    </Box>
    <Stack fill>
      <InfiniteScroll items={entries} step={5}>
        {(entry: DocumentData, index: number) => (
          <Box
            key={`entry-box-${index}`}
            round="xsmall"
            pad="medium"
            direction="row"
            justify="between"
            align="center"
            margin={{ vertical: 'small' }}
            background={backgroundForEntry(entry as Entry)}
          >
            <Radial id={`entry-${index}`} size="large" color="brand" />
            <Box basis="10%"><Text weight="bold">{entry.date.toLocaleDateString()}</Text></Box>
            <Box basis="10%"><Text size="xxlarge">{dietLabel(entry[Meal.BREAKFAST])}</Text></Box>
            <Box basis="10%"><Text size="xxlarge">{dietLabel(entry[Meal.LUNCH])}</Text></Box>
            <Box basis="10%"><Text size="xxlarge">{dietLabel(entry[Meal.DINNER])}</Text></Box>
            {/* <Box basis="10%"><Text size="xxlarge">{dietLabel(entry[4])}</Text></Box> */}
          </Box>
        )}
      </InfiniteScroll>
    </Stack>
  </Box>
);

const InfiniteEntriesPage = () => {
  const [entries, setEntries] = useState<Entry[]>([]);

  const toEntrySlice = (snap: QuerySnapshot<Entry>) => {
    setEntries(snap.docs.map((doc) => doc.data()).sort(sortEntry).reverse());
  };

  // const entry = entryMap[toISODate(date)] ?? {};

  useEffect(
    () => {
      if (auth.currentUser?.uid !== undefined) {
        return watchEntries(auth.currentUser.uid, toEntrySlice);
      }
      return undefined;
    },
    [auth.currentUser],
  );

  return (
    <SidebarLayout>
      <Box flex="grow">
        <Box as="section" width="xlarge">
          <Heading level={1}>All Entries</Heading>
          {entries && <InfiniteEntries entries={entries} />}
        </Box>
      </Box>
    </SidebarLayout>
  );
};

export default MustBeLoggedIn(InfiniteEntriesPage);
