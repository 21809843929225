import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Box, Grid, Sidebar, Image, List, ResponsiveContext, Nav, Main, Avatar, Button,
} from 'grommet';
import {
  Home, User, Logout, List as ListIcon, Menu as MenuIcon,
} from 'grommet-icons';

import Routes from '../routes';
import logoText from '../img/LogoText.svg';
import { doSignOut } from './SignOut';
import { auth } from '../firebase';

const logo = () => (
  <Box width="small">
    <a href={Routes.HOME}><Image src={logoText} alt="vegstreak" fill /></a>
  </Box>
);

declare interface SidebarItem {
  label: string
  icon: React.ReactElement,
  href?: string,
  onClick?: () => void,
}

const VegstreakMenu = () => {
  const history = useHistory();
  return (
    <List<SidebarItem>
      data={[
        { label: 'Home', href: Routes.HOME, icon: <Home /> },
        { label: 'Entries', href: Routes.ENTRIES, icon: <ListIcon /> },
        {
          label: 'Account',
          href: Routes.ACCOUNT,
          icon: (
            auth.currentUser?.photoURL
              ? <Avatar size="small" src={auth.currentUser.photoURL} />
              : <User />
          ),
        },
        { label: 'Sign Out', icon: <Logout />, onClick: () => { doSignOut(history); } },
      ]}
      primaryKey="icon"
      secondaryKey="label"
      onClickItem={(event: { item?: SidebarItem; index?: number }) => {
        if (event.item?.onClick) {
          event.item.onClick();
        }
        if (event.item?.href) {
          history.push(event.item.href);
        }
      }}
    />
  );
};

export default ({ children }: { children: React.ReactElement }) => {
  const size = useContext(ResponsiveContext);
  const [hidden, setHidden] = useState<boolean>(true);

  // Hide the sidebar when the width is too small
  let sidebarWidth = 'medium';
  let navbarWidth = '0';
  sidebarWidth = size === 'medium' ? 'small' : sidebarWidth;
  sidebarWidth = size === 'xsmall' || size === 'small' ? '0' : sidebarWidth;
  navbarWidth = size === 'xsmall' || size === 'small' ? 'xsmall' : navbarWidth;

  if (!hidden && (size === 'medium' || size === 'large' || size === 'xlarge')) {
    setHidden(true);
  }

  return (
    <Grid
      fill
      rows={[navbarWidth, 'auto']}
      columns={[sidebarWidth, 'flex']}
      areas={[
        { name: 'navbar', start: [1, 0], end: [1, 0] },
        { name: 'sidebar', start: [0, 1], end: [0, 1] },
        { name: 'main', start: [1, 1], end: [1, 1] },
      ]}
    >
      <Nav gridArea="navbar" background="brand" pad="small" justify="between" align="center" direction="row">
        {logo()}
        <Button plain icon={<MenuIcon />} onClick={() => setHidden(!hidden)} />
      </Nav>
      <Sidebar gridArea="sidebar" background="brand" header={logo()}>
        <VegstreakMenu />
      </Sidebar>
      <Main gridArea="main" align="center" background="light-1">
        {hidden ? children : (
          <Box flex="grow" background="brand" style={{ display: hidden ? 'none' : undefined }} fill>
            <VegstreakMenu />
          </Box>
        )}
      </Main>
    </Grid>
  );
};
