import React, { useContext, useEffect, useState } from 'react';
import { QuerySnapshot } from '@firebase/firestore';

import {
  Box, Button, Calendar, RenderProps, Text, Card, ResponsiveContext, Grid,
} from 'grommet';
import { Previous, Next } from 'grommet-icons';

import { auth, addEntry, watchEntries } from '../firebase';
import {
  dietColor,
  dietLabel,
  dietName,
  mealLabel,
  toISODate,
} from '../utils';
import { Entry, Meal } from '../types';
import { removeEntry } from '../firebase/db';

declare type CalendarDaySVGProps = {
  isSelected: boolean,
  isToday: boolean,
  day: number,
  entry: Entry,
  size: string,
};

// Render a 3 segment pie chart for Breakfast, Lunch, Dinner where the colors
// are based on the diet. Hard coded Arcs as the Trig was slow when done dynamically
// The viewBox is used to zoom in and out on the svg. In the form x, y, width, height.
const CalendarDaySVG = React.memo(({
  isSelected, isToday, day, entry, size,
}: CalendarDaySVGProps) => {
  const offset = isSelected ? 1 : 2;
  const fallbackColor = entry.date && entry.date <= (new Date()) ? 'lightgrey' : 'white';
  const breakfast = dietColor(entry[Meal.BREAKFAST]) ?? fallbackColor;
  const lunch = dietColor(entry[Meal.LUNCH]) ?? fallbackColor;
  const dinner = dietColor(entry[Meal.DINNER]) ?? fallbackColor;
  return (
    <svg
      height={size}
      version="1.1"
      width={size}
      viewBox={`-${offset} -${offset} ${10 + 2 * offset} ${10 + 2 * offset}`}
      className={`${isSelected ? 'isSelected' : undefined} ${isToday ? 'isToday' : undefined}`}
    >
      <path d="M 5 5 L 9.33013 7.5 A 5 5 0 0 0 5 0 Z" fill={breakfast} stroke={breakfast} strokeWidth={0.2} />
      <path d="M 5 5 L 0.66987 7.5 A 5 4.99 0 0 0 9.33013 7.5 Z" fill={lunch} stroke={lunch} strokeWidth={0.2} />
      <path d="M 5 5 L 5 0 A 5 5 0 0 0 0.66987 7.5 Z" fill={dinner} stroke={dinner} strokeWidth={0.2} />
      <text x="5" y="5" fontSize="5px" textAnchor="middle" dominantBaseline="middle" fontWeight="bold">{day}</text>
    </svg>
  );
});

// A Grommet Calendar with custom rendering of each day to show a pie chart
// Requires css overrides to ensure the wrapper Day element is display:flex
// Without this the week rows jump. Change overrides.css when changing relative
// depth of Calendar.
const Cal = (
  { entries, date, setDate }: {
    entries: Record<string, Entry>,
    date: Date,
    setDate: React.Dispatch<React.SetStateAction<Date>>,
  },
) => {
  const today = new Date();
  const todayString = today.toDateString();
  const responsive = useContext(ResponsiveContext);
  const size = responsive === 'xsmall' || responsive === 'small' ? '40px' : '50px';
  return (
    <Box basis="1/2" flex="grow">
      <Calendar
        daysOfWeek
        firstDayOfWeek={1}
        date={toISODate(date)}
        showAdjacentDays={false}
        className="cal"
        fill
        bounds={['2018-10-01', toISODate(today)]}
      >
        {(props: RenderProps) => {
          const entry: Entry = entries[toISODate(props.date)] ?? {};
          return (
            <Box
              align="center"
              justify="center"
              width={size}
              height={size}
              className="cal-day"
              onClick={(props.date <= today) ? (() => setDate(props.date)) : undefined}
            >
              <CalendarDaySVG
                isSelected={props.isSelected}
                isToday={props.date.toDateString() === todayString}
                day={props.day}
                entry={entry}
                size={size}
              />
            </Box>
          );
        }}
      </Calendar>
    </Box>
  );
};

export default () => {
  const [meal, setMeal] = useState<number>(1);
  const [date, setDate] = useState<Date>(new Date());
  const [error, setError] = useState<Error | null>(null);
  const [entries, setEntries] = useState<Record<string, Entry>>({});

  const entry: Entry = entries[toISODate(date)] ?? {};

  const toEntryMap = (snap: QuerySnapshot<Entry>) => {
    const output: Record<string, Entry> = {};
    snap.forEach((doc) => {
      output[toISODate(doc.data().date)] = doc.data();
    });
    setEntries(output);
  };

  useEffect(
    () => {
      if (auth.currentUser?.uid !== undefined) {
        return watchEntries(auth.currentUser.uid, toEntryMap);
      }
      return undefined;
    },
    [auth.currentUser],
  );

  // Change the state date by the value in days
  // i.e. increase the date by 1 day
  const buttonDate = (value: number) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + value);
    setDate(newDate);
  };

  // onClick handler to add an Entry to the database using db function addEntry
  // This needs to be an arrow function to avoid having to use `bind(this)`
  const upsertEntry = (dietButton: number) => {
    // if the user is clicking the same diet this should remove the entry
    if (entry[meal] === dietButton) {
      removeEntry(auth.currentUser?.uid || '', date, meal);
    } else {
      addEntry(auth.currentUser?.uid || '', date, meal, dietButton)
        .then(() => setError(null))
        .catch((e) => setError(e));
    }
  };

  // Disable the next button if it will put us in the future
  const nextDisabled = date.toDateString() === (new Date()).toDateString();

  return (
    <Card fill direction="row-responsive" wrap pad="medium" flex="grow" overflow="visible">
      <Cal entries={entries} date={date} setDate={setDate} />
      <Box flex="grow" basis="1/2" justify="between" align="stretch" alignContent="stretch">
        <Box flex={false} direction="row" justify="center" align="center" height="38.5px">
          <Button icon={<Previous color="accent-1" />} onClick={() => { buttonDate(-1); }} plain />
          <Text tag="strong" textAlign="center">{date.toLocaleDateString('en-GB')}</Text>
          <Button icon={<Next color="accent-1" />} onClick={() => { buttonDate(1); }} plain disabled={nextDisabled} />
        </Box>
        <Grid
          columns={{ count: 'fit', size: ['80px', 'flex'] }}
          style={{ gridAutoFlow: 'column' }}
          fill="horizontal"
          gap="small"
          height="xsmall"
          margin={{ bottom: 'small' }}
        >
          {[1, 2, 3].map((id) => (
            <Button
              key={`meal-${id}`}
              label={mealLabel(id)}
              primary={meal === id}
              onClick={() => { setMeal(id); }}
              color={dietColor(entry[id]) ?? 'lightgrey'}
              margin={{ top: 'small' }}
            />
          ))}
        </Grid>
        <Box flex={{ grow: 3 }}>
          <Grid fill columns={['flex', 'flex']} rows={['flex', 'flex', 'flex']} gap="xsmall">
            {[1, 2, 3, 4, 5, 6].map((id) => (
              <Button
                key={`diet-${id}`}
                label={`${dietLabel(id)} ${dietName(id)}`}
                primary={entry[meal] === id}
                onClick={() => upsertEntry(id)}
                color={dietColor(id)}
              />
            ))}
          </Grid>
        </Box>
        <Box flex="shrink">
          {error && (
            <Text color="tomato" weight="bold">
              {JSON.stringify(error, null, 4)}
            </Text>
          )}
        </Box>
      </Box>
    </Card>
  );
};
