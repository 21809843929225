import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { onAuthStateChanged, User } from 'firebase/auth';

import { auth } from '../firebase';
import Routes from '../routes';
import { RedirectLocationState } from '../types';

declare type AuthFunction = (authUser: User | null, history: History<RedirectLocationState>) => boolean;

export const withAuthorization = (
  authCondition: AuthFunction,
  to: Routes,
) => (Wrapped: React.ElementType) => () => {
  const [render, setRender] = useState<boolean>(false);
  const history = useHistory<RedirectLocationState>();

  useEffect(
    () => onAuthStateChanged(auth, (authUser) => {
      if (authCondition(authUser, history)) {
        setRender(true);
      } else {
        setRender(false);
        history.push(to);
      }
    }),
    [auth.currentUser],
  );

  return render ? <Wrapped /> : null;
};

export const MustBeLoggedIn = withAuthorization(
  (authUser: User | null) => !!authUser,
  Routes.SIGN_IN,
);

export const MustNotBeLoggedIn = withAuthorization(
  (authUser: User | null) => authUser === null,
  Routes.HOME,
);

export const MustBeSigningIn = withAuthorization(
  (authUser: User | null, history: History<RedirectLocationState>) => {
    if (history.location.state?.redirectTo !== null) {
      return true;
    }
    return authUser === null;
  },
  Routes.HOME,
);
