/* eslint-disable max-len */
import React, { useState } from 'react';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { logEvent } from '@firebase/analytics';

import {
  Box, Paragraph, FormField, TextInput, Button, Image,
} from 'grommet';

import { SignUpLink } from './SignUp';
import { PasswordForgetLink } from './PasswordForget';

import { analytics, auth } from '../firebase';
import Routes from '../routes';
import logo from '../img/logo.png';
import { MustBeSigningIn } from './withAuthorization';
import { RedirectLocationState } from '../types';

export const SignInForm = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<Error | null>(null);
  const history = useHistory<RedirectLocationState>();

  // Get the redirect url from state or default to HOME
  const redirect = history.location.state?.redirectTo ?? Routes.HOME;

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setEmail('');
        setPassword('');
        setError(null);
        logEvent(analytics, 'login', { method: 'email' });
        history.push(redirect);
      })
      .catch((e: Error) => {
        setError(e);
      });
    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <FormField htmlFor="email" label="Email" content={{ pad: 'large' }}>
        <TextInput id="email" value={email} onChange={(event) => setEmail(event.target.value)} type="email" />
      </FormField>
      <FormField htmlFor="password" label="Password" content={{ pad: 'large' }}>
        <TextInput id="password" value={password} onChange={(event) => setPassword(event.target.value)} type="password" />
      </FormField>
      {error && <Paragraph>{JSON.stringify(error)}</Paragraph>}
      <Box>
        <Button label="Sign In" type="submit" primary fill />
      </Box>
    </form>
  );
};

export const SignInFormWithRouter = withRouter(SignInForm);

export const SignInLink = () => (
  <Box fill>
    <p>Already have an account?</p>
    <Link to={Routes.SIGN_IN}>
      <Button label="Sign In" fill />
    </Link>
  </Box>
);

const SignInPage = () => (
  <Box flex="grow">
    <Box align="center" fill pad="medium" background="brand">
      <Link to={Routes.LANDING}>
        <Image src={logo} alt="vegstreak logo" style={{ maxWidth: '350px' }} />
      </Link>
      <Box width="medium" pad="medium" round="xsmall" background="light-1">
        <SignInFormWithRouter />
        <PasswordForgetLink />
        <SignUpLink />
      </Box>
    </Box>
  </Box>
);

export default MustBeSigningIn(SignInPage);
