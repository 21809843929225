import React from 'react';

import {
  Box, Image, Heading, Sidebar, Main,
} from 'grommet';

import { SignInLink } from './SignIn';
import { SignUpFormWithRouter } from './SignUp';
import logo from '../img/logo.png';
import { MustNotBeLoggedIn } from './withAuthorization';

const LandingPage = () => (
  <Main wrap direction="row">
    <Box background="brand" flex="grow" basis="2/3" align="center">
      <Box pad="large">
        <Image src={logo} alt="vegstreak logo" style={{ width: '80%', maxWidth: '350px' }} />
      </Box>
      <Box pad="large" background="light-2">
        <h2>Introducing Vegstreak: Your Companion to a Healthier Lifestyle</h2>
        <p>
          Welcome to Vegstreak, your personalized companion on the path to a healthier, more conscious way of living.
          Our platform is designed to empower you in making mindful choices about your diet, tracking your meal
          preferences, and celebrating your progress. Whether you&apos;re a committed vegetarian, a dedicated vegan, or
          an occasional meat-eater, Vegstreak caters to your unique journey towards a balanced and sustainable
          lifestyle.
        </p>
      </Box>

      <Box pad="large">
        <h2>Track Your Meals, Celebrate Your Choices</h2>
        <p>
          With Vegstreak, meal tracking has never been more effortless. Easily log your breakfast, lunch, and dinner
          preferences each day, and let our platform do the rest. Our intuitive interface allows you to select from
          three options - vegetarian, vegan, or meat - and record your choices with a single tap. Witness your meal
          trends unfold as you build a clear picture of your eating habits and gain insights into your progress over
          time.
        </p>
      </Box>

      <Box pad="large" background="light-2">
        <h2>Unleash the Power of Streaks</h2>
        <p>
          Staying motivated is key to maintaining a healthy routine, and Vegstreak&apos;s streak tracking feature is
          here to inspire you every step of the way. Watch your streak grow as you consistently opt for vegetarian or
          vegan meals, or even discover how moderation complements your lifestyle with the occasional indulgence in
          meat-based dishes. Celebrate each milestone, and let the streaks guide you towards achieving your wellness
          goals.
        </p>
      </Box>

      <Box pad="large">
        <h2>Personalized Guidance, Anytime, Anywhere</h2>
        <p>
          At Vegstreak, we understand that your health journey is as unique as you are. That&apos;s why our platform
          provides tailored recommendations and personalized insights to support your progress. Whether you&apos;re a
          seasoned health enthusiast or just starting, we&apos;re here to offer helpful tips, recipe suggestions, and
          expert advice to ensure you&apos;re equipped with the resources you need to make informed choices.
        </p>
      </Box>

      <Box pad="large" background="light-2">
        <h2>Join the Vegstreak Community Today</h2>
        <p>
          Sign up for a Vegstreak account today and become part of a vibrant community of like-minded individuals
          dedicated to embracing healthier lifestyles. Let us guide you on your wellness voyage, from discovering
          delicious recipes to celebrating your accomplishments. Embrace the power of conscious eating and embark on a
          Vegstreak towards a healthier, happier you. Together, we&apos;ll make every meal count!
        </p>
      </Box>
    </Box>

    <Sidebar background="light-1" flex="grow" pad="medium" justify="center">
      <Heading as="h3">
        Sign Up
      </Heading>
      <SignUpFormWithRouter />
      <SignInLink />
    </Sidebar>
  </Main>
);

export default MustNotBeLoggedIn(LandingPage);
