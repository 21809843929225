import React, { useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import {
  updatePassword, updateEmail, updateProfile, User,
} from '@firebase/auth';

import {
  Box, Heading, Form, FormField, TextInput, Button, Avatar, List,
} from 'grommet';
import { Analytics } from 'grommet-icons';

import { MustBeLoggedIn } from './withAuthorization';
import SidebarLayout from './SidebarLayout';
import { auth } from '../firebase';
import Routes from '../routes';
import { RedirectLocationState } from '../types';
import { calculateStatistics } from '../firebase/db';

const REDIRECT_TO_AUTH_LOCATION = {
  pathname: Routes.SIGN_IN,
  state: { redirectTo: { pathname: Routes.ACCOUNT, state: { reauthed: 'true' } } },
};

declare type AccountDetails = {
  displayName?: string | null;
  photoURL?: string | null;
};

const AccountDetailsForm = () => {
  const [value, setValue] = useState<AccountDetails>({
    displayName: auth.currentUser?.displayName,
    photoURL: auth.currentUser?.photoURL,
  });
  const history = useHistory();
  return (
    <Form<AccountDetails>
      value={value}
      onChange={(nextValue) => setValue(nextValue)}
      onReset={() => setValue({})}
      onSubmit={(event) => {
        updateProfile(auth.currentUser as User, event.value)
          .catch(() => history.push(REDIRECT_TO_AUTH_LOCATION));
        auth.currentUser?.reload();
      }}
    >
      <FormField name="displayName" htmlFor="displayName-input" label="Name">
        <TextInput id="displayName-input" name="displayName" />
      </FormField>
      <FormField name="photoURL" htmlFor="photoURL-input" label="Photo">
        <TextInput id="photoURL-input" name="photoURL" />
      </FormField>
      <Box direction="row" gap="medium">
        <Button type="submit" primary label="Submit" />
        <Button type="reset" label="Reset" />
      </Box>
    </Form>
  );
};

declare type AccountEmail = {
  emailOne: string | null;
  emailTwo: string | null;
};

const AccountEmailForm = () => {
  const initialState = {
    emailOne: auth.currentUser?.email ?? null,
    emailTwo: auth.currentUser?.email ?? null,
  };
  const [value, setValue] = useState<AccountEmail>({ ...initialState });
  const history = useHistory();
  return (
    <Form<AccountEmail>
      value={value}
      onChange={(nextValue) => setValue(nextValue)}
      onReset={() => setValue({ ...initialState })}
      onSubmit={(event) => {
        if (event.value.emailOne && event.value.emailOne === event.value.emailTwo) {
          updateEmail(auth.currentUser as User, event.value.emailOne)
            .catch(() => history.push(REDIRECT_TO_AUTH_LOCATION));
          setValue({ ...initialState });
          auth.currentUser?.reload();
        }
      }}
    >
      <FormField name="emailOne" htmlFor="emailOne-input" label="Email">
        <TextInput id="emailOne-input" name="emailOne" />
      </FormField>
      <FormField name="emailTwo" htmlFor="emailTwo-input" label="Confirm Email">
        <TextInput id="emailTwo-input" name="emailTwo" />
      </FormField>
      <Box direction="row" gap="medium">
        <Button type="submit" primary label="Submit" />
        <Button type="reset" label="Reset" />
      </Box>
    </Form>
  );
};

declare type AccountPassword = {
  passwordOne: string;
  passwordTwo: string;
};

const AccountPasswordForm = () => {
  const initialState = { passwordOne: '', passwordTwo: '' };
  const [value, setValue] = useState<AccountPassword>({ ...initialState });
  const history = useHistory();
  return (
    <Form<AccountPassword>
      value={value}
      onChange={(nextValue) => setValue(nextValue)}
      onReset={() => setValue({ ...initialState })}
      onSubmit={(event) => {
        if (event.value.passwordOne && event.value.passwordOne === event.value.passwordTwo) {
          updatePassword(auth.currentUser as User, event.value.passwordOne)
            .catch(() => history.push(REDIRECT_TO_AUTH_LOCATION));
          setValue({ ...initialState });
          auth.currentUser?.reload();
        }
      }}
    >
      <FormField name="passwordOne" htmlFor="passwordOne-input" label="New Password">
        <TextInput id="passwordOne-input" name="passwordOne" type="password" />
      </FormField>
      <FormField name="passwordTwo" htmlFor="passwordTwo-input" label="Confirm Password">
        <TextInput id="passwordTwo-input" name="passwordTwo" type="password" />
      </FormField>
      <Box direction="row" gap="medium">
        <Button type="submit" primary label="Submit" />
        <Button type="reset" label="Reset" />
      </Box>
    </Form>
  );
};

const ReauthButton = ({ label }: { label: string }) => (
  // A link which goes to the sign in page with state to redirect back with reauthed=true
  // This is so we can show the buttons only after a recent sign in otherwise firebase
  // will error
  <Link<RedirectLocationState> to={REDIRECT_TO_AUTH_LOCATION}>
    <Button label={label} fill />
  </Link>
);

const CalculateStatisticsButton = () => {
  const [color, setColor] = useState<string>('accent-1');

  const generateStats = () => {
    if (auth.currentUser?.uid) {
      calculateStatistics(auth.currentUser.uid, () => setColor('brand'));
    }
  };

  // eslint-disable-next-line max-len
  return <Button icon={<Analytics />} onClick={generateStats} color={color} disabled={color === 'brand'} label="Generate" />;
};

const AccountPage = () => {
  const location = useLocation<Record<string, string>>();
  return (
    <SidebarLayout>
      <Box flex="grow">
        <Box as="section" width="xlarge">
          <Heading level={1}>Your Profile</Heading>
          <Box direction="row" align="center">
            {auth.currentUser?.photoURL && <Avatar src={auth.currentUser.photoURL} margin="small" />}
          </Box>
        </Box>
        <Box as="section" width="xlarge">
          <Heading level={3}>Your Details</Heading>
          <AccountDetailsForm />
        </Box>
        <Box as="section" width="xlarge">
          <Heading level={3}>Your Email</Heading>
          {
            location.state?.reauthed === 'true'
              ? <AccountEmailForm />
              : <ReauthButton label="Login to change email" />
          }
        </Box>
        <Box as="section" width="xlarge">
          <Heading level={3}>Your Password</Heading>
          {
            location.state?.reauthed === 'true'
              ? <AccountPasswordForm />
              : <ReauthButton label="Login to change password" />
          }
        </Box>
        <Box as="section" width="xlarge">
          <Heading level={3}>Actions</Heading>
          <List
            data={[
              {
                button: <CalculateStatisticsButton />,
                desc: 'Sometime we need to regenerate your statistics based on all of your entries.',
              },
            ]}
            primaryKey="button"
            secondaryKey="desc"
          />
        </Box>
      </Box>
    </SidebarLayout>
  );
};

export default MustBeLoggedIn(AccountPage);
