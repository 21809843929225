import React, { useEffect, useState } from 'react';
import {
  Box, Distribution, Text,
} from 'grommet';

import { VegstreakUser } from '../types';
import { dietColor, dietLabel } from '../utils';
import { auth } from '../firebase';
import { watchUser } from '../firebase/db';

declare interface Statistic {
  text: string
  color: string
  value: number
}

export const sortStats = (a: Statistic, b: Statistic) => b.value - a.value;

const Statistics = () => {
  const [user, setUser] = useState<VegstreakUser | undefined>(undefined);

  useEffect(
    () => {
      if (auth.currentUser?.uid !== undefined) {
        return watchUser(auth.currentUser.uid, (snap) => setUser(snap.data()));
      }
      return undefined;
    },
    [auth.currentUser],
  );

  if (user?.totalEntries) {
    const total = user.totalEntries;
    const values = Object.entries(user?.statistics ?? {}).map(([k, vv]) => ({
      text: dietLabel(Number(k)),
      color: dietColor(Number(k)),
      value: (vv / total),
    }));
    values.sort(sortStats);
    return (
      <Distribution fill basis="auto" values={values}>
        {(v) => (
          <Box pad="xsmall" background={v.color} fill round="small" direction="row-responsive" gap="small">
            <Text size="xxlarge">
              {`${v.text}`}
            </Text>
            <Text size="large" weight="bold" color="black">
              {v.value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}
            </Text>
          </Box>
        )}
      </Distribution>
    );
  }
  return null;
};

export default Statistics;
