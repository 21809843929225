enum Routes {
    SIGN_UP = '/signup',
    SIGN_IN = '/signin',
    LANDING = '/',
    HOME = '/home',
    ACCOUNT = '/account',
    PASSWORD_FORGET = '/forgot-password',
    ENTRIES = '/entries',
}

export default Routes;
