// Helpers

import { Diet, Meal, Entry } from './types';

// Helper function to get the nice icon for a diet
export const dietLabel = (n: Diet): string => ['', '🐄', '🐷', '🐓', '🐟', '🥕', '🥑'][n];

// Helper function to get the nice color for a diet
export const dietColor = (n: Diet): string => [
  '', 'tomato', 'lightpink', 'gold', 'lightblue', 'lightgreen', 'green',
][n];

// Helper function to get the nice name for a diet
export const dietName = (n: Diet): string => ['', 'Meat', 'Pork', 'Chicken', 'Fish', 'Veggie', 'Vegan'][n];

// Helper function to get the nice name for a meal
export const mealLabel = (n: Meal): string => ['', 'Breakfast', 'Lunch', 'Dinner', 'Snacks'][n];

// Helper function to get the nice color for a meal
export const mealColor = (n: Meal): string => ['', 'accent-1', 'accent-2', 'accent-3', 'accent-4'][n];

// Get the ISO date only without time
export const toISODate = (date: Date): string => date.toISOString().split('T')[0];

// Sorter for Entry objects

export const sortEntry = (a: Entry, b: Entry) => {
  if (a.date < b.date) { return 1; }
  if (a.date === b.date) { return 0; }
  return 1;
};
