import React from 'react';

import {
  Box, Heading,
} from 'grommet';

import { auth } from '../firebase';
import Controls from './Controls';
import Statistics from './Statistics';
import { MustBeLoggedIn } from './withAuthorization';
import SidebarLayout from './SidebarLayout';

const HomePage = () => (
  <SidebarLayout>
    {/* This wrapper box is required to set flex-shrink: 0. Otherwise everything is squashed into window height */}
    <Box flex="grow">
      <Box tag="section" pad="medium" width="xlarge">
        <Heading>
          {`Hello, ${auth.currentUser?.displayName ?? 'There'}`}
        </Heading>
      </Box>

      <Box tag="section" pad="medium" width="xlarge">
        <Controls />
      </Box>

      <Box tag="section" pad="medium" width="xlarge" height="medium">
        <Heading level={2}>Statistics</Heading>
        <Statistics />
      </Box>
    </Box>
  </SidebarLayout>
);

export default MustBeLoggedIn(HomePage);
