import {
  doc, setDoc, onSnapshot, collection, QuerySnapshot,
  DocumentSnapshot, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions,
  getDocs, increment, runTransaction, DocumentData, deleteField,
} from '@firebase/firestore';
import { db } from './firebase';
import { toISODate } from '../utils';
import { Entry, VegstreakUser } from '../types';

// User API

export const VegstreakUserConverter: FirestoreDataConverter<VegstreakUser> = {
  toFirestore: (user) => (user),
  fromFirestore: (snap: QueryDocumentSnapshot, options?: SnapshotOptions) => snap.data(options) as VegstreakUser,
};

export const EntryConverter: FirestoreDataConverter<Entry> = {
  toFirestore: (entry) => (entry),
  fromFirestore: (snap: QueryDocumentSnapshot, options?: SnapshotOptions) => {
    const data = snap.data(options);
    return {
      ...data,
      date: data.date.toDate(),
      created: data.created.toDate(),
    };
  },
};

export const createUser = (id: string, username: string, email: string) => setDoc<VegstreakUser>(
  doc(db, 'user', id).withConverter(VegstreakUserConverter),
  { username, email },
);

export const watchUser = (
  id: string,
  onNext: (snapshot: DocumentSnapshot<VegstreakUser>) => void,
) => onSnapshot(
  doc(db, 'user', id).withConverter(VegstreakUserConverter),
  onNext,
);

export const calculateStatistics = (id: string, next: () => void) => {
  getDocs(collection(db, 'user', id, 'entry').withConverter(EntryConverter))
    .then((snap) => {
      // Must be same length as Diet
      const counter: Record<number, number> = {
        0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0,
      };
      snap.forEach((d: QueryDocumentSnapshot<Entry>) => {
        const entry = d.data();
        // Must be same length as Meal
        [1, 2, 3].forEach((meal) => {
          if (meal in entry) {
            counter[entry[meal]] += 1;
          }
        });
      });
      const total = Object.values(counter).reduce((p, c) => p + c, 0);
      setDoc(
        doc(db, 'user', id),
        { statistics: counter, totalEntries: total },
        { merge: true },
      ).then(next);
    });
};

export const watchEntries = (
  id: string,
  onNext: (snapshot: QuerySnapshot<Entry>) => void,
) => onSnapshot(
  collection(db, 'user', id, 'entry').withConverter(EntryConverter),
  onNext,
);

// Use a transaction to get the current document
export const addEntry = (userId: string, date: Date, meal: number, diet: number) => runTransaction(
  db,
  async (transaction) => {
    // Create reference to new or existing Entry
    const entryRef = doc(db, 'user', userId, 'entry', toISODate(date)).withConverter(EntryConverter);

    // See if there is a current value on the Entry
    // All reads must come before all writes!
    const current = (await transaction.get<Entry>(entryRef)).data();
    const currentDiet = current?.[meal];

    // Update / Create the Entry
    const entry = {
      date,
      [meal]: diet,
      created: new Date(),
    };
    transaction.set<Entry>(entryRef, entry, { merge: true });

    // Build the new statistics
    const user: DocumentData = {
      statistics: { [diet]: increment(1) },
    };
    if (!currentDiet) {
      // If there was no current value it is new so increase the total
      user.totalEntries = increment(1);
    } else if (currentDiet !== diet) {
      // if current value is not null and different to the new value
      user.statistics[currentDiet] = increment(-1);
    }

    // Create reference to owner user doc
    const userRef = doc(db, 'user', userId).withConverter(VegstreakUserConverter);
    // Update the user statistics
    transaction.set<VegstreakUser>(userRef, user, { merge: true });
  },
);

export const removeEntry = (userId: string, date: Date, meal: number) => runTransaction(
  db,
  async (transaction) => {
    // Create reference to new or existing Entry
    const entryRef = doc(db, 'user', userId, 'entry', toISODate(date)).withConverter(EntryConverter);

    // Get the current entry
    const current = (await transaction.get<Entry>(entryRef)).data();
    // see if this is the last meal for this entry
    if (current && Object.keys(current).filter(Number).length === 1) {
      transaction.delete(entryRef);
    } else {
      transaction.update<Entry>(entryRef, { [meal]: deleteField() });
    }
  },
);
