import { LocationDescriptor } from 'history';

export enum Diet {
  UNKNOWN = 0,
  MEAT = 1,
  PORK = 2,
  CHICKEN = 3,
  FISH = 4,
  VEGGIE = 5,
  VEGAN = 6,
}

export enum Meal {
  UNKNOWN = 0,
  BREAKFAST = 1,
  LUNCH = 2,
  DINNER = 3,
}

export interface Statistics {
  [diet: number]: number
}

export interface VegstreakUser {
  username: string
  email: string
  statistics?: Statistics
  totalEntries?: number
}

export interface Entry {
  date: Date,
  created: Date,
  [meal: number]: Diet,
}

export interface HomePageState {
  entries?: Entry[]
}

export interface RedirectLocationState {
  redirectTo?: LocationDescriptor<Record<string, string>>
}
