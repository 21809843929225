/* eslint-disable max-len */
import React, { useState } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { logEvent } from '@firebase/analytics';
import {
  Box, Paragraph, FormField, TextInput, Button, Image,
} from 'grommet';

import { SignInLink } from './SignIn';

import { analytics, auth } from '../firebase';
import { createUser } from '../firebase/db';
import Routes from '../routes';
import logo from '../img/logo.png';
import { MustNotBeLoggedIn } from './withAuthorization';

export const SignUpForm = () => {
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [passwordOne, setPasswordOne] = useState<string>('');
  const [passwordTwo, setPasswordTwo] = useState<string>('');
  const [error, setError] = useState<Error | null>(null);
  const history = useHistory();

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    if (passwordOne !== passwordTwo) {
      setError(new Error('passwords do not match'));
      return null;
    }

    createUserWithEmailAndPassword(auth, email, passwordOne)
      .then((authUser) => {
        // Update the firebase auth profile too
        updateProfile(authUser.user, { displayName: username })
          .catch((e: Error) => { setError(e); });

        // Create a user in your own accessible Firebase Database too
        createUser(authUser.user.uid, username, email)
          .then(() => {
            setUsername('');
            setEmail('');
            setPasswordOne('');
            setPasswordTwo('');
            setError(null);
          })
          .catch((e: Error) => { setError(e); });

        // Log someone signed up then redirect to the HomePage
        logEvent(analytics, 'sign_up', { method: 'email' });
        history.push(Routes.HOME);
      })
      .catch((e: Error) => { setError(e); });
    event.preventDefault();
    return null;
  };

  return (
    <form onSubmit={onSubmit}>
      <FormField htmlFor="username" label="Name" content={{ pad: 'large' }}>
        <TextInput id="username" value={username} onChange={(event) => setUsername(event.target.value)} type="text" />
      </FormField>
      <FormField htmlFor="email" label="Email" content={{ pad: 'large' }}>
        <TextInput id="email" value={email} onChange={(event) => setEmail(event.target.value)} type="email" />
      </FormField>
      <FormField htmlFor="passwordOne" label="Password" content={{ pad: 'large' }}>
        <TextInput id="passwordOne" value={passwordOne} onChange={(event) => setPasswordOne(event.target.value)} type="password" />
      </FormField>
      <FormField htmlFor="passwordTwo" label="Confirm Password" content={{ pad: 'large' }}>
        <TextInput id="passwordTwo" value={passwordTwo} onChange={(event) => setPasswordTwo(event.target.value)} type="password" />
      </FormField>
      {error && <Paragraph>{error.message}</Paragraph>}
      <Box>
        <Button label="Sign Up" type="submit" primary fill />
      </Box>
    </form>
  );
};

export const SignUpFormWithRouter = withRouter(SignUpForm);

export const SignUpLink = () => (
  <Box fill>
    <p>Don&apos;t have an account?</p>
    <Link to={Routes.SIGN_UP}>
      <Button label="Sign Up" fill />
    </Link>
  </Box>
);

const SignUpPage = () => (
  <Box flex="grow">
    <Box align="center" fill pad="medium" background="brand">
      <Link to={Routes.LANDING}>
        <Image src={logo} alt="vegstreak logo" style={{ maxWidth: '350px' }} />
      </Link>
      <Box width="medium" pad="medium" round="xsmall" background="light-1">
        <SignUpFormWithRouter />
        <SignInLink />
      </Box>
    </Box>
  </Box>
);

export default MustNotBeLoggedIn(SignUpPage);
